<template lang="pug">
.Authorization.Container(:style="isAtril ? 'color: #545454;' : 'color: #545454; font-size: 1.3rem;'")
  .banderaSvg
  .Content
    .CardWhite.mx-auto
      .py-4
        h3.fonterH3.fonterH3NoDate.mb-1.mx-auto
          | Autorización de datos personales
      .mb-1.mt-0.px-5.container-texts
        p.fonterNoDate.textHero.m-0.mx-auto.d-block.mb-4
          | En cumplimiento de la Ley 1581 de 2012 y demás normas concordantes,
          | la FSFB como institución que almacena y recolecta datos personales
          | para realizar los trámites administrativos relacionados con su proceso de atención,
          | enviarle información relacionada con la evaluación de satisfacción, así como campañas comerciales,
          | requiere obtener su autorización para que de manera libre, previa, expresa, voluntaria, y debidamente informada,
          | le permita a la Institución recolectar, recaudar, almacenar, usar, circular, suprimir, procesar, compilar, intercambiar,
          | actualizar y disponer de los datos como nombre, teléfono, dirección física y electrónica, entre otros que serán suministrados
          | y que serán  incorporados en distintas bases o bancos de datos.
        P.fonterNoDate.textHero.m-0.mx-auto.d-block.mb-4
          | De igual forma por ser el titular de los datos personales usted podrá conocer, actualizar y rectificar sus datos personales,
          | solicitar prueba de la autorización otorgada a la Fundación Santa Fe de Bogotá, revocar la autorización y/o solicitar la supresión
          | del dato cuando en el Tratamiento no se respeten los principios, derechos y garantías constitucionales y legales. Para dudas e inquietudes
          | relacionadas con el tratamiento de datos personales puede escribirnos al correo electrónico datospersonales@fsfb.org.co. o consultar nuestra
          | política de tratamiento de datos personales dispuesta en la página web
          strong.linking.ml-1 www.fundacionsantafedebogota.com.
        p.fonterNoDate.textHero.m-0.mx-auto.d-block.mb-3.small-txt.pb-2
          | Lo anterior hace exclusiva referencia a lo señalado arriba y no se relaciona con la información consignada en las historias clínicas,
          | debido a que los datos y la información allí consignada conservan su carácter reservado, confidencial y totalmente privado (Resolución 1995 de 1999).
        p.fonterNoDate.textHero.m-0.mx-auto.d-block.mb-0.subtitle
          | Por lo tanto, ¿Autorizo a la Fundación Santa Fe de Bogotá?
    .mt-0.d-flex.gap-3.justify-content-center
      button.arrowButton.p-4.w-100.my-3.text-center.button-without-styles.mx-3(
        :class="selectedThing === 'Si' && 'selectedThing'"
        block
        @click="() => onSelectedCheckbox('Si', 1)"
      ).d-flex
        //- div(v-html="selectIcon(act.label)")
        img(src="@/assets/mobileFSFB/si-verde.svg" alt="Si")
        span.my-4.w-100.text-center.d-block.fontButton Si
        .position-relative.container
          input(
            type="checkbox"
            id="1"
            disabled
          ).checkBoxArrow
          .checkmark
      button.arrowButton.p-4.w-100.my-3.text-center.button-without-styles.mx-3(
        :class="selectedThing === 'No' && 'selectedThing'"
        block
        @click="() => onSelectedCheckbox('No', 2)"
      ).d-flex
        img(src="@/assets/mobileFSFB/error.png" alt="No")
        span.my-4.w-100.text-center.d-block.fontButton No
        .position-relative.container
          input(
            type="checkbox"
            id="2"
            disabled
          ).checkBoxArrow
          .checkmark
    .pb-2
      button(
        :class="!selectedThing ? 'disabled' : 'active'"
        :disabled="!selectedThing"
        @click="calculateAction(selectedThing)"
      ).text-center.buttonService.containerForm.mt-3
        | Continuar
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Authorization",
  data() {
    return {
      selectedThing: ""
    };
  },
  computed: {
    ...mapState({
      menu: state => state.virtualrowFSFB.menu,
      citas: state => state.virtualrowFSFB.citas,
      isAtril: state => state.virtualrowFSFB.isAtril,
    }),
  },
  methods: {
    ...mapActions({
      setMenus: "virtualrowFSFB/setMenus",
      goToView: "virtualrowFSFB/goToView",
      setAuthorize: "virtualrowFSFB/setAuthorize",
    }),
    onSelectedCheckbox(act, id) {
      this.selectedThing = act;
      const $checkBox = document.getElementById(id);
      const allCheckBoxes = document.querySelectorAll(".checkBoxArrow");

      allCheckBoxes.forEach($checkboxParam => ($checkboxParam.checked = ""));

      $checkBox.checked = "true";
    },
    calculateAction() {
      // check view
      let newmenu = []
      for (let index = 0; index < this.menu.length; index++) {
        let _menu = this.menu[index];
        if(_menu.children) {
          let newChildrens = [];
          // filter childrens
          for (let index = 0; index < _menu.children.length; index++) {
            let child = _menu.children[index];
            if(this.selectedThing == "Si" && child.authorize) {
              newChildrens.push(child)
            }
            if(this.selectedThing == "No" && !child.authorize) {
              newChildrens.push(child)
            }
          }
          _menu.children = newChildrens;
          newmenu.push(_menu)
        } else {

          // check menu authorize when the menu option don't have children
          if(this.selectedThing == "Si" && _menu.authorize) {
            newmenu.push(_menu)
          }
          if(this.selectedThing == "No" && !_menu.authorize) {
            newmenu.push(_menu)
          }
          
        }
      }
      this.setAuthorize(this.selectedThing == "Si" ? true : false);
      this.setMenus(newmenu);
      this.goToView({ view: "ExistentUser" });
    }
  }
}
</script>

<style lang="scss" scoped>
.container-texts {
  max-width: 2000px;
  width: 100%;
}

.small-txt {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 31px;
  text-align: center;
  color: #788591;
}

.link {
  color: #001698;
}

.subtitle {
  color: black;
}

.buttonService,
.buttonServiceModal {
  font-family: "Gilroy-Light";
  width: 100%;
  max-width: 476px;
  margin: auto;
  display: block;
  height: 89px;
  padding: 1.5rem 2rem;
  background-color: #009dac;
  border-radius: 20px;
  font-style: normal;
  font-weight: 300;
  font-size: 35px;
  line-height: 45px;
  border: none;
  outline: none;
  text-align: center;
  color: #ffffff;
  transition: 1s background-color;
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.25));
}

.buttonService {
  font-family: "Gilroy-Semibold";
  width: 100%;
  padding: 1.5rem 2rem;
  background-color: #009dac;
  border-radius: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 34px;
  border: none;
  outline: none;
  text-align: center;
  color: #ffffff;
  transition: 1s background-color;
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.25));
}

.outlinedServiceButton {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 25px;
  color: #009dac;
}

/* checkbox styles */

.container:hover input ~ .checkmark {
  background-color: #ffffff;
}

.container input:checked ~ .checkmark {
  background: url(../../assets/checkbox.svg);
  border: none;
  background-size: cover;
  background-repeat: no-repeat;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 30px;
  width: 30px;
  background-color: #ffffff;
  border: 2px solid #788591;
  border-radius: 5px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.selectedThing {
  font-family: "Gilroy-Semibold";
  color: #009dac;
  border: 3px solid #18a2af;
}

.arrowButton {
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 25px;
  color: #454d55;
  background: #ffffff;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  height: 206px;
  width: 300px !important;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.arrowButton img {
  max-width: 90px;
  width: 100%;
  height: 50px;
  object-fit: contain;
}

.checkBoxArrow {
  width: 26px;
  height: 26px;
  border: 2px solid #788591;
  visibility: hidden;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.buttonService:disabled {
  background-color: #f2f2f2;
  color: #dedede;
  filter: none;
}
</style>